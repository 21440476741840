import React, { useState } from 'react';
import axios from 'axios';

function ResetPasswordForm({ token, switchToLogin }) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (password !== confirmPassword) {
      setError('Пароли не совпадают');
      return;
    }

    try {
      const response = await axios.post(
        `api/auth/reset-password/${token}`, 
        { password },
        { headers: { 'CSRF-Token': document.cookie.split('csrfToken=')[1] } } // отправляем CSRF токен
      );
      setSuccessMessage(response.data.message);
    } catch (err) {
      setError('Ошибка смены пароля: ' + err.response?.data?.message || err.message);
    }
  };

  return (
    <form onSubmit={handlePasswordChange}>
      <h2>Введите новый пароль:</h2>
      <input
        type="password"
        placeholder="Пароль"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Подтвердите пароль"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      <button type="submit">Сменить пароль</button>
      <a href="#" onClick={switchToLogin}>Вернуться к авторизации</a>
    </form>
  );
}

export default ResetPasswordForm;
