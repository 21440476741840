import React from 'react';

function EmailSentForm({ message, timeLeft, onResend }) {
  return (
    <div>
      <h2>{message}</h2>
      <p>Ссылка будет активна ещё {timeLeft} минут</p>
      <button onClick={onResend}>Отправить письмо снова</button>
    </div>
  );
}

export default EmailSentForm;
