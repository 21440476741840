import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function EmailConfirmedForm() {
  const location = useLocation();
  const navigate = useNavigate();

  // Получаем тип токена из URL
  const tokenType = new URLSearchParams(location.search).get('tokenType');

  // Логируем для проверки
  console.log("Token from URL:", tokenType);

  // Валидация токена
  const isValidToken = tokenType === 'registration' || tokenType === 'passwordReset';

  // Логируем результат валидации
  useEffect(() => {
    console.log("Checking token validity...");
    if (!isValidToken) {
      console.log("Token is invalid or missing, redirecting...");
      navigate('/', { replace: true }); // Редирект на главную
    } else {
      console.log("Token is valid, showing form...");
      navigate('/success', { replace: true }); // Перенаправление на /success
    }
  }, [isValidToken, navigate]);

  // В зависимости от токена, показываем сообщение
  const renderMessage = () => {
    switch (tokenType) {
      case 'registration':
        return 'Регистрация успешно подтверждена!';
      case 'passwordReset':
        return 'Пароль успешно восстановлен!';
      default:
        return 'Что-то пошло не так, пожалуйста, попробуйте позже.';
    }
  };

  return (
    <div>
      <h2>{renderMessage()}</h2>
      <button onClick={() => window.close()}>Закрыть</button>
    </div>
  );
}

export default EmailConfirmedForm;
