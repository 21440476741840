import React, { useState } from 'react';
import axios from 'axios';

function ForgotPasswordForm({ switchToLogin, onSuccess }) {
  const [emailOrLogin, setEmailOrLogin] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Изменили на isSubmitting

  const validateForm = () => {
    if (emailOrLogin.includes('@')) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Проверка email
      if (!emailRegex.test(emailOrLogin)) {
        setError('Неверный формат логина или email');
        return false;
      }
    } else {
      const loginRegex = /^[a-zA-Z0-9-_]{3,16}$/; // Логин должен быть от 3 до 16 символов и содержать буквы, цифры, "-", "_"
      if (!loginRegex.test(emailOrLogin)) {
        setError('Неверный формат логина или email');
        return false;
      }
    }  
    setError(''); // Очищаем ошибки, если валидация прошла
    return true;
  };  

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return; // Останавливаем выполнение, если валидация не прошла
    }
      
    setIsSubmitting(true); // Блокируем форму и показываем спинер

    try {
      const response = await axios.post(
        '/api/auth/forgot-password',
        { emailOrLogin },
        { headers: { 'CSRF-Token': document.cookie.split('csrfToken=')[1] } }
      );
      // Переходим к форме "Отправка письма" без отображения успеха на этой форме
      onSuccess('Мы отправили вам письмо для подтверждения Восстановления пароля');
    } catch (err) {
      setError('Ошибка восстановления пароля: ' + (err.response?.data?.message || err.message));
    } finally {
      setIsSubmitting(false); // Разблокируем форму после завершения запроса
    }
  };

  return (
    <form onSubmit={handlePasswordReset}>
      <h2>Восстановление пароля</h2>
      <input
        type="text"
        placeholder="Email или Логин"
        value={emailOrLogin}
        onChange={(e) => {
          setEmailOrLogin(e.target.value);
          setError(''); // Очищаем ошибку при изменении поля
        }}
        required
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button type="submit" disabled={isSubmitting}>Восстановить</button>
        {isSubmitting && (
          <div className="spinner" style={{ marginLeft: '10px' }}></div>
        )}
      </div>
      
      <a href="#" onClick={switchToLogin}>Вернуться к авторизации</a>
      
      <style jsx>{`
        .spinner {
          border: 4px solid #f3f3f3;
          border-top: 4px solid #3498db;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          animation: spin 2s linear infinite;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </form>
  );
}

export default ForgotPasswordForm;
