import React, { useState } from 'react';
import axios from 'axios';

function RegisterForm({ switchToLogin, onSuccess }) {
  const [login, setLogin] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Валидация формы
  const validateForm = () => {
    const loginRegex = /^[a-zA-Z0-9_-]{3,16}$/; // Логин от 3 до 16 символов
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/; // Пароль с буквами и цифрами, минимум 8 символов
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; // Простой валидатор email

    if (!loginRegex.test(login)) {
      setError('Логин должен быть от 3 до 16 символов и содержать только латинские буквы, цифры и символы "-" / "_"');
      return false;
    }
    if (!emailRegex.test(email)) {
      setError('Введите правильный email');
      return false;
    }
    if (!passwordRegex.test(password)) {
      setError('Пароль должен содержать минимум 1 букву и 1 цифру и быть длиной от 8 символов');
      return false;
    }
    if (password !== confirmPassword) {
      setError('Пароли не совпадают');
      return false;
    }

    setError('');
    return true;
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await axios.post(
        'api/auth/register', 
        { login, email, password },
        { headers: { 'CSRF-Token': document.cookie.split('csrfToken=')[1] } }
      );
      onSuccess('Мы отправили вам письмо для подтверждения Регистрации');
    } catch (err) {
      setError('Ошибка регистрации: ' + err.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleRegister}>
      <h2>Регистрация</h2>
      <input
        type="text"
        placeholder="Логин"
        value={login}
        onChange={(e) => {
          setLogin(e.target.value);
          setError('');
        }}
        required
        disabled={isSubmitting}
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => {
          etEmail(e.target.value);
          setError('');
        }}
        required
        disabled={isSubmitting}
      />
      <input
        type="password"
        placeholder="Пароль"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setError('');
        }}          
        required
        disabled={isSubmitting}
      />
      <input
        type="password"
        placeholder="Подтвердите пароль"
        value={confirmPassword}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
          setError('');
        }}
        required
        disabled={isSubmitting}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button type="submit" disabled={isSubmitting}>Зарегистрироваться</button>
        
        {isSubmitting && (
          <div className="spinner" style={{ marginLeft: '10px' }}></div>
        )}
      </div>
      
      <a href="#" onClick={switchToLogin}>Уже есть аккаунт? Войти</a>
      
      <style jsx>{`
        .spinner {
          border: 4px solid #f3f3f3;
          border-top: 4px solid #3498db;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          animation: spin 2s linear infinite;
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </form>
  );
}

export default RegisterForm;
