// src/components/Header.jsx
import React from 'react';
import AuthButton from '../../../../components/UI/AuthButton';
import '../../styles/header.css'; // Стили для заголовка

const Header = ({ openLoginForm }) => {
  return (
    <header id="hero">
      <div className="container">
        <h1>Эхо Потерянных Веков</h1>
        <p>Исследуй мир и открывай тайны древних цивилизаций!</p>
        <a href="#game" className="btn">Играть</a>
        {/* Кнопка входа/регистрации */}
        <AuthButton openLoginForm={openLoginForm} />
      </div>
    </header>
  );
};

export default Header;
