// Modal.jsx
import React from 'react';
import '../../sites/main_site/styles/Modal.css'; // Путь к вашему CSS файлу

const Modal = ({ children, closeModal }) => {
  return (
    <div className="modal open">
      <div className="modal-content">
        <button onClick={closeModal} className="close-btn">X</button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
