// src/components/UI/AuthButton.jsx
import React from 'react';
import '../../sites/main_site/styles/authButton.css';  // Стиль для кнопки

const AuthButton = ({ openLoginForm }) => {
  return (
    <button onClick={openLoginForm} className="auth-button">
      Вход/Регистрация
    </button>
  );
};

export default AuthButton;
