import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'; // Импортируем BrowserRouter
import App from './App';
import './sites/main_site/styles/styles.css';

// Монтируем приложение в элемент с id 'root' в файле public/index.html
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter> {/* Оборачиваем приложение в BrowserRouter */}
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root') // Рендерим в элемент с id="root"
);
