import React, { useState } from 'react';
import LoginForm from './sites/main_site/components/Auth/LoginForm';
import RegisterForm from './sites/main_site/components/Auth/RegisterForm';
import PasswordResetForm from './sites/main_site/components/Auth/PasswordResetForm';
import EmailSentForm from './sites/main_site/components/Auth/EmailSentForm';
import EmailConfirmedForm from './sites/main_site/components/Auth/EmailConfirmedForm';
import ForgotPasswordForm from './sites/main_site/components/Auth/ForgotPasswordForm';
import Modal from './components/UI/Modal';
import AuthButton from './components/UI/AuthButton';
import Header from './sites/main_site/components/structure/Header';
import Footer from './sites/main_site/components/structure/Footer';
import './sites/main_site/styles/styles.css';


function App() {
  const [currentForm, setCurrentForm] = useState('login');  // Текущая форма (login, register, и т.д.)
  const [isModalOpen, setIsModalOpen] = useState(false);  // Открыто ли модальное окно
  const [emailSentMessage, setEmailSentMessage] = useState('');  // Сообщение для формы отправки email
  const [confirmationMessage, setConfirmationMessage] = useState('');  // Сообщение для формы подтверждения

  // Открытие формы логина в модальном окне
  const openLoginForm = () => {
    setCurrentForm('login');
    setIsModalOpen(true);
  };

  // Закрытие модального окна
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Объединённая функция для успешной отправки email
  const handleEmailSent = (message) => {
    setEmailSentMessage(message);
    setCurrentForm('emailSent');
  };

  // Объединённая функция для успешного подтверждения
  const handleConfirmation = (message) => {
    setConfirmationMessage(message);
    setCurrentForm('emailConfirmed');
  };

  // Функция для рендеринга формы в зависимости от состояния currentForm
  const renderForm = () => {
    switch (currentForm) {
      case 'login':
        return <LoginForm switchToRegister={() => setCurrentForm('register')} switchToPasswordReset={() => setCurrentForm('forgotPassword')} />;
      case 'register':
        return <RegisterForm switchToLogin={() => setCurrentForm('login')} onSuccess={(message) => handleEmailSent(message)} />;
      case 'forgotPassword':
        return <ForgotPasswordForm switchToLogin={() => setCurrentForm('login')} onSuccess={(message) => handleEmailSent(message)}/>;
      case 'passwordReset':
        return <PasswordResetForm onSuccess={(message) => handleConfirmation(message)} />;
      case 'emailSent':
        return <EmailSentForm 
                  message={emailSentMessage} 
                  tokenExpiryTime={600} // Пример времени жизни токена в секундах (5 минут)
                  onResend={() => setCurrentForm('emailSent')}
                  onSuccessRegistration={() => setCurrentForm('emailConfirmed')}  // Переход на форму подтверждения
                  onSuccessForgotPassword={() => setCurrentForm('passwordReset')} // Переход на форму смены пароля
                />;
      case 'emailConfirmed':
        return <EmailConfirmedForm message={confirmationMessage} />;
      default:
        return null;
    }
  };

  return (
    <div className="App">
      {/* Заголовок страницы */}
      <Header openLoginForm={openLoginForm} />
      
      {/* Основной контент */}
      <main>
        {/* Убираем Routes, так как он не нужен в текущей реализации */}
        <AuthButton openLoginForm={openLoginForm} />
      </main>

      {/* Модальное окно с текущей формой */}
      {isModalOpen && (
        <Modal closeModal={closeModal}>
          {renderForm()}
        </Modal>
      )}

      {/* Футер страницы */}
      <Footer />
    </div>
  );
}

export default App;

