import React, { useState } from 'react';

function LoginForm({ switchToRegister, switchToPasswordReset }) {
  const [emailOrLogin, setEmailOrLogin] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    // Здесь будет логика авторизации
    console.log("Logging in with", emailOrLogin, password);
  };

  return (
    <form onSubmit={handleLogin}>
      <h2>Войдите в систему</h2>
      <input
        type="text"
        placeholder="Email или Логин"
        value={emailOrLogin}
        onChange={(e) => setEmailOrLogin(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Пароль"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <button type="submit">Войти</button>
      <a href="#" onClick={switchToPasswordReset}>Забыли пароль?</a>
      <p>--- или зарегистрируйтесь ---</p>
      <button type="button" onClick={switchToRegister}>Регистрация</button>
    </form>
  );
}

export default LoginForm;
